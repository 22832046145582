import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TypingMessageQueue } from '../TypingMessageQueue.ts'
import { EventSourceMessage, useEventSource } from '../useEventSource.ts'
import GlobalContext from './GlobalContext.tsx'
import MyMarkdown from './Markdown.tsx'
const host = window.location.hostname.includes('localhost')
  ? 'http://localhost:3000'
  : 'https://frchgpt-api.gpt.liip.ch'

const EVENT_SOURCE_NAME = 'function'

export const ResultFunction = (props: {
  functionQuery: { query: string; function: string } | null
  userId: string | null
}) => {
  const [sseUri, setSseUri] = useState('')
  const { t } = useTranslation()
  const [functionResult, setFunctionResult] = useState<string>('')
  const { mainLanguage, org } = useContext(GlobalContext)
  const { close: eventSourceClose, start: eventSourceStart } = useEventSource()

  useEffect(() => {
    if (props.functionQuery) {
      setSseUri(
        `${host}/${org}/assistant/sse?function=${encodeURIComponent(
          props.functionQuery?.function,
        )}&query=${encodeURIComponent(
          `${props.functionQuery?.query} mode:mixtral`,
        )}&key=X9hL4Gp5W2D7eRtF&r=${Math.random()}${props.userId ? `&uID=${props.userId}` : ''}&lang=${mainLanguage}`,
      )
    }
  }, [mainLanguage, org, props.functionQuery, props.userId])

  const parseMessageFromEventSource = useCallback((parsedData: Record<string, any>, queue: TypingMessageQueue) => {
    try {
      if (parsedData.links) {
        return
      }
      if (parsedData.usedLinks) {
        return
      }

      if (parsedData.response) {
        if (parsedData.response === '__THIS_IS_THE_ANSWER_END__') {
          queue.setThisIsTheEnd()

          return
        }
        if (parsedData.response === '__CLR__') {
          queue.enqueue('')

          return
        }
        queue.enqueue(parsedData.response)
      }
    } catch (error) {
      console.log(error)
    }
  }, [])

  useEffect(() => {
    let linksReceived = false
    try {
      if (!sseUri) {
        return
      }
      const queue = new TypingMessageQueue(setFunctionResult)
      const onerror = (e: Event) => {
        if (!linksReceived) {
          setFunctionResult(queryResult =>
            queryResult.concat(`

${t('answers.serverConnectionError')}`),
          )
        }
        console.log('SSE Error', e)
        eventSourceClose(EVENT_SOURCE_NAME)
      }
      const onmessage = (event: EventSourceMessage) => {
        const parsedData = JSON.parse(event.data)

        if (parsedData.links) {
          linksReceived = true
        }
        parseMessageFromEventSource(parsedData, queue)
      }
      eventSourceStart(EVENT_SOURCE_NAME, sseUri, onmessage, onerror)

      return () => {
        eventSourceClose(EVENT_SOURCE_NAME)
      }
    } catch (error) {
      console.log(error)
    }
  }, [eventSourceClose, eventSourceStart, parseMessageFromEventSource, sseUri, t])

  return (
    <>
      <p>
        <em>Alternative Antwort mit Daten von offenen APIs (OpenERZ oder timetable.search.ch):</em>
      </p>
      <div translate="no">
        <MyMarkdown markdown={functionResult}></MyMarkdown>
      </div>
      <hr />
    </>
  )
}
